import { AppSettings } from "booking_app/values/app-settings";
import { GlobalStateService } from "booking_app/services/global-state.service";
import { LoginModalSizeOption, LoginModalWindowClassOption } from "booking_app/types/login-modal-config";
import { Tenant } from "booking_app/types";

angular.module("BookingApp").config([
  "$routeProvider", "$injector", "KaligoConfig",
  ($routeProvider, $injector, KaligoConfig) => {
    $routeProvider
    .when("/company/jal-travel-faqs", {
      controller: "PageCtrl",
      templateUrl: "/html/company/faqs2",
    })
    .when("/company/faqs", {
      redirectTo: "/company/jal-travel-faqs",
    })
    .when("/terms/jal-travel-terms-and-conditions", {
      controller: "PageCtrl",
      templateUrl: "/html/terms/terms_condition2",
    })
    .when("/terms/terms_condition", {
      redirectTo: "/terms/jal-travel-terms-and-conditions",
    })
    .when("/terms/jal-travel-privacy-policy", {
      controller: "PageCtrl",
      templateUrl: "/html/terms/privacy_policy2",
    })
    .when("/terms/privacy", {
      redirectTo: "/terms/jal-travel-privacy-policy",
    })
    .when("/:travelType", {
      controller: "SearchCtrl",
      templateUrl: "/html/whitelabel/jal/search",
      resolve: {
        parseUrl: $injector.get("ParseTrafficParams"),
      },
    });
  },
]);

class JalController {

  static $inject = [
    "$rootScope",
    "AppSettings",
    "KaligoConfig",
    "GlobalStateService",
  ];

  constructor(
    private $rootScope: any,
    private appSettings: AppSettings,
    private kaligoConfig: any,
    private globalStateService: GlobalStateService,
  ) {
    this.appSettings.enableChildrenSearch = true;
    this.appSettings.showPerNightText = true;
    this.appSettings.carsListBtnLabel = "Select";
    this.appSettings.checkoutPagePlaceholderText = {
      firstName: "(e.g) Taro",
      lastName: "(e.g) Yamada",
    };
    this.appSettings.landingPageUrl = {
      earn: "wl-jal-earn",
      redeem: "wl-jal-redeem",
    };
    this.appSettings.mapboxHotelMarkerColor = "#CD0000";
    this.appSettings.mapboxHotelClusterColor = "rgba(205,0,0,0.7)";
    this.appSettings.mapboxHotelClusterCounterTextColor = "#FFFFFF";
    this.appSettings.maximumAvailableBookingMonths = 10;
    this.appSettings.showGulfAlert = false;
    this.appSettings.reloadOnSearch = true;
    this.appSettings.reloadOnQuickSearch = true;
    this.appSettings.preFilterUnavailableHotels = true;
    this.appSettings.loginModalConfig.loginModalWindowClass = LoginModalWindowClassOption.LOGIN_MODAL_VALIDATION_CLASS;
    this.appSettings.loginModalConfig.loginModalSize = LoginModalSizeOption.MEDIUM_SIZE;
    this.appSettings.tenant = Tenant.JAL;
    this.appSettings.mobileResultPopupConfig = {
      filterIconSource: "jal/icons/filter-icon.png",
      sortIconSource: "jal/icons/sort-icon.png",
      mapIconSource: "jal/icons/map-icon.png",
    };
    this.appSettings.hotelDetailsTemplateConfig.earnRewardLabel = "wl.jal.total_earn";
    this.appSettings.hotelDetailsTemplateConfig.showExtraHeadingsRoomList = true;
    this.appSettings.hotelDetailsTemplateConfig.showPointCashSliderRoomList = false;
    this.appSettings.hotelDetailsTemplateConfig.doubleMobileRoomPricesButton = true;
    this.appSettings.carsBookingPage.viewManageBookingText = "purchase.button.go_to_my_booking";
    this.appSettings.carsBookingPage.nonRefundableText = "booking_detail.cancellation_policy.non_refundable_not_permitted";
    this.appSettings.stripePaymentIntentsEnabled = true;

    const currentTime: number = Date.now();

    this.appSettings.payWithPoints.roundToNearest = 2000;

    // Date.UTC(2025, 1, 28, 15) -> 28 Feb 2025, 15:00 UTC (which is 1 Mar 00:00 JST)
    if (currentTime >= Date.UTC(2025, 1, 28, 15)) {
      this.appSettings.payWithPoints.roundToNearestByCouponCodeSettings = {
        hotels: {
          redeem: 1,
        }
      };
    }

    this.appSettings.prismicSettings.enabled = true;
    this.appSettings.prismicSettings.faqs = "te-jal--faq";
    this.appSettings.prismicSettings.terms = "te-jal--terms";
    this.appSettings.prismicSettings.privacyPolicy = "te-jal--privacy-policy";

    const march2025Min = Date.UTC(2025, 1, 28, 15); // 28 Feb 2025, 15:00 UTC (which is 1 Mar 00:00 JST)
    const march2025Max = Date.UTC(2025, 2, 31, 15); // March 31, 2025, 15:00 UTC (which is April 1, 2025, 00:00 JST)
    const may2025Max = Date.UTC(2025, 4, 31, 15); // May 31, 2025, 15:00 UTC (which is June 1, 2025, 00:00 JST)

    if (currentTime >= march2025Min && currentTime < march2025Max) {
      this.appSettings.mastheadBannerSettings.landing_page_banner.hotels = "march-2025";
      this.appSettings.mastheadBannerSettings.landing_page_banner.cars = "march-2025";

      this.appSettings.mastheadBannerSettings.startTime = march2025Min;
      this.appSettings.mastheadBannerSettings.endTime = march2025Max;
    } else if (currentTime >= march2025Min && currentTime < may2025Max) {
      this.appSettings.mastheadBannerSettings.landing_page_banner.hotels = "appril-to-may-2025";
      this.appSettings.mastheadBannerSettings.landing_page_banner.cars = "appril-to-may-2025";

      this.appSettings.mastheadBannerSettings.startTime = march2025Min;
      this.appSettings.mastheadBannerSettings.endTime = may2025Max;
    } else {
      // Update banner on April 1, 2024 JST to April 30, 2024 JST
      // Date.UTC(2024, 2, 31, 15) => 2024-03-31 15:00 UTC => 2024-04-01 00:00 JST (inclusive)
      // Date.UTC(2024, 3, 30, 15) => 2024-04-30 15:00 UTC => 2024-05-01 00:00 JST (exclusive)
      this.appSettings.mastheadBannerSettings.landing_page_banner.hotels = "april-2024";
      this.appSettings.mastheadBannerSettings.startTime = Date.UTC(2024, 2, 31, 15);
      this.appSettings.mastheadBannerSettings.endTime = Date.UTC(2024, 3, 30, 15);
    }

    this.$rootScope = $rootScope;
    this.$rootScope.globalState.productType = kaligoConfig.PRODUCT_TYPE;
    this.$rootScope.globalState.useSimpleLoader = false;
    this.appSettings.pointsCashSliderSettings.sliderRightLabel = "txt.cash";
    this.appSettings.pointsCashSliderSettings.sliderLeftLabel = this.$rootScope.pointsPartner.shortCurrency;
    this.$rootScope.globalState.serviceEmail = "support@jmbworldmarketplace.com";
    this.$rootScope.globalState.hideUseAddressFromProfile = true;
    this.$rootScope.globalState.requireLoginOnCheckout = true;
    this.$rootScope.abTest = { withConfidence: "no" };
    this.$rootScope.globalState.customLoaderFile = "/html/whitelabel/jal/custom_loader";
    this.appSettings.hasCouponCode = true;
    this.$rootScope.couponCodeApplied = false;
  }

}

angular.module("BookingApp").controller("JalController", JalController);
